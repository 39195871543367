<template>
  <div
    class="rounded-md border text-base dark:border-gray-600 dark:bg-gray-800"
    :class="{
      'border-red-300 bg-red-100 text-red-900 dark:text-red-500':
        type === 'error',
      'border-yellow-400 bg-yellow-200 text-yellow-900 dark:text-yellow-500':
        type === 'warning',
      'border-green-400 bg-green-100 text-green-900 dark:text-green-500':
        type === 'success',
      'border-gray-400 bg-gray-100/25 text-gray-900 dark:text-gray-300':
        type === 'neutral',
      'p-2': size === 'sm',
      'p-4': size === 'lg',
    }"
  >
    <div class="flex">
      <div
        class="flex-shrink-0"
        :class="{
          'text-red-600': type === 'error',
          'text-yellow-600': type === 'warning',
          'text-green-600': type === 'success',
          'text-gray-500 dark:text-gray-400': type === 'neutral',
        }"
      >
        <WarningFill
          v-if="type === 'warning' || type === 'error'"
          class="size-5"
        />
        <CheckCircleFill v-if="type === 'success'" class="size-5" />
        <InfoFill v-if="type === 'neutral'" class="size-5" />
      </div>
      <div class="ml-3 flex-1">
        <h3 v-if="title" class="mb-1 font-semibold leading-snug">
          {{ title }}
        </h3>
        <div class="empty:hidden">
          <slot />
        </div>
        <div class="-my-1.5 alert-actions -mx-2 mt-2 empty:hidden">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WarningFill from '@web/components/utilities/svg/phosphorIcons/WarningFill.vue';
import CheckCircleFill from '@web/components/utilities/svg/phosphorIcons/CheckCircleFill.vue';
import InfoFill from '@web/components/utilities/svg/phosphorIcons/InfoFill.vue';

export default {
  components: { InfoFill, CheckCircleFill, WarningFill },
  props: {
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
      validator: (value) =>
        ['error', 'warning', 'success', 'neutral'].indexOf(value) !== -1,
    },
    size: {
      type: String,
      default: 'lg',
      validator: (size) => ['sm', 'lg'].includes(size),
    },
  },
};
</script>

<style>
.alert-actions {
  button,
  a {
    @apply mr-1 cursor-pointer rounded px-2 py-1 text-sm font-semibold transition hover:underline focus:outline-none disabled:cursor-not-allowed disabled:hover:no-underline;
  }
}
</style>
